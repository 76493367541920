import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/exp/drugtest/page',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/exp/drugtest/remove',
    method: 'post',
    params: {
      ids
    }
  })
}
export const submit = (row) => {
  return request({
    url: '/exp/drugtest/submit',
    method: 'post',
    data: row
  })
}

export const deleteDrugTest = (id) => {
  return request({
    url: '/exp/drugtest/delete/' + id,
    method: 'post'
  })
}
// 获取药敏药品字典
export const getDrugDict = () => {
  return request({
    url: '/exp/drug/dict',
    method: 'get'
  })
}
// 获取孔板信息
export const getDrugDetail = (experimentId,
  formId,
  holeIndex) => {
  return request({
    url: '/exp/experimentdrugdetail/drugDetail',
    method: 'get',
    params: {
      experimentId,
      formId,
      holeIndex
    }
  })
}

// 获取报告数据
export const getDrugReport = (experimentId) => {
  return request({
    url: '/exp/drugtest/report',
    method: 'get',
    params: {
      id: experimentId
    }
  })
}
// 生成报告
export const saveReport = (data) => {
  return request({
    url: '/common/downloadWord',
    method: 'post',
    data
  })
}
// 保存药敏培养
export const submitVr = (data) => {
  return request({
    url: '/vr/exp/drugtest/submit',
    method: 'post',
    data
  })
}
// 获取步骤详情
export const getStepDetail = (stepId) => {
  return request({
    url: '/vr/exp/drugtest/step/detail',
    method: 'get',
    params: {
      stepId
    }
  })
}
export const getDosageDict = () => {
  return request({
    url: '/exp/dosage/dict',
    method: 'get'
  })
}
export const uploadEx = (data) => {
  return request({
    url: '/vr/exp/drugtest/uploadExcel',
    method: 'post',
    data
  })
}

export const saveEx = (data) => {
  return request({
    url: '/vr/exp/drugtest/exportExcel',
    method: 'post',
    data
  })
}
